.landing-desktop,
.landing-desktop * {
    box-sizing: border-box;
}

.landing-desktop {
    background: #ffffff;
    width: 1352px;
    height: 4253px;
    /* position: relative; */
    overflow: hidden;
}


/* .section-5 {
    position: absolute;
    inset: 0;
} */

.copywright-2021-website-com {
    text-align: center;
    font: 700 15px/18px "Raleway", sans-serif;
    position: absolute;
    left: 611px;
    top: 4197px;
}

.copywright-2021-website-com-span {
    color: var(--dark-primary, #000000);
    font: 700 15px/18px "Raleway", sans-serif;
}

.copywright-2021-website-com-span2 {
    color: var(--secondtext, #5c5c5c);
    font: 700 15px/18px "Raleway", sans-serif;
}

.saly-6 {
    width: 500px;
    height: 500px;
    position: absolute;
    left: 1218px;
    top: 3753px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.saly-62 {
    width: 500px;
    height: 500px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.youtube {
    position: absolute;
    left: 783.26px;
    top: 3886.57px;
    transform: translate(-6.06px, 1.59px);
    overflow: visible;
}

.facebook {
    position: absolute;
    left: 841.11px;
    top: 3901.6px;
    transform: translate(-3.69px, 2.83px);
    overflow: visible;
}

.saly-22 {
    width: 73px;
    height: 218px;
    position: absolute;
    left: 755.51px;
    top: 4022px;
    transform-origin: 0 0;
    transform: rotate(59.076deg) scale(-1, 1);
}

.saly-222 {
    width: 47px;
    height: 153px;
    position: absolute;
    left: 1175px;
    top: 3802px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.hey-follow-us-on-social-media-so-you-don-t-miss-any-offer {
    color: var(--dark-primary, #000000);
    text-align: left;
    font: 700 40px/57px "Raleway", sans-serif;
    position: absolute;
    left: 311px;
    top: 3846px;
    width: 429px;
}

.twitter {
    position: absolute;
    left: 889.99px;
    top: 3918.79px;
    transform: translate(-3.34px, 1.34px);
    overflow: visible;
}


/* .section-4 {
    position: absolute;
    inset: 0;
} */

.box {
    background: #fab8c5;
    border-radius: 50px;
    width: 1048px;
    height: 509px;
    position: absolute;
    left: 196px;
    top: 3215px;
}


/* .image {
    width: 718px;
    height: 718px;
    position: static;
} */

.saly-24 {
    width: 718px;
    height: 718px;
    position: absolute;
    left: 633px;
    top: 3006px;
}

.saly-242 {
    width: 718px;
    height: 718px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.image-14 {
    border-radius: 28.57px;
    border-style: solid;
    border-color: #e16c71;
    border-width: 6.67px;
    width: 180px;
    height: 380.95px;
    position: absolute;
    left: 907px;
    top: 3161.93px;
}

.save-time-money-with-exclusive-offers-from-top-stores {
    color: #5956e9;
    text-align: left;
    font: 700 40px/57px "Raleway", sans-serif;
    position: absolute;
    left: 311px;
    top: 3307px;
    width: 407px;
}

.button-large {
    width: 314px;
    height: 70px;
    position: absolute;
    left: 311px;
    top: 3508px;
}

.rectangle-2 {
    background: var(--primary, #5956e9);
    border-radius: 10px;
    width: 314px;
    height: 70px;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.button {
    color: #f6f6f9;
    text-align: center;
    font: 700 20px "Raleway", sans-serif;
    position: absolute;
    left: 12px;
    top: 25px;
    width: 290px;
}


/* .section-3 {
    position: absolute;
    inset: 0;
} */


/* 
.group-759 {
    width: 515px;
    height: 290px;
    position: static;
} */

.original-platform {
    color: var(--primary, #5956e9);
    text-align: left;
    font: 700 24px/36px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 2606px;
}

.hottest-deals-around-the-web {
    color: var(--dark-primary, #000000);
    text-align: left;
    font: 700 60px/67px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 2659px;
    width: 515px;
}

.find-the-perfect-gift-or-everyday-goods-right-at-your-fingertips {
    color: var(--secondtext, #737373);
    text-align: left;
    font: 500 24px/38px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 2820px;
    width: 515px;
}


/* .image2 {
    width: 443px;
    height: 521px;
    position: static;
} */


/* .mobile {
    width: 255px;
    height: 510px;
    position: static;
} */

.image-12 {
    background: linear-gradient(to left, #dcdefe, #dcdefe);
    border-radius: 33.41px;
    border-style: solid;
    border-color: transparent;
    border-width: 1.11px;
    width: 255px;
    height: 510px;
    position: absolute;
    left: 308px;
    top: 2491px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.image-13 {
    border-radius: 30px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    width: 229px;
    height: 484px;
    position: absolute;
    left: 321px;
    top: 2504px;
}

.saly-223 {
    width: 73px;
    height: 218px;
    position: absolute;
    left: 262px;
    top: 2770px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.saly-224 {
    width: 47px;
    height: 153px;
    position: absolute;
    left: 632px;
    top: 2480px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}


/* .section-2 {
    position: absolute;
    inset: 0;
} */


/* .group-758 {
    width: 1048px;
    height: 576px;
    position: static;
} */

.rectangle-83 {
    background: linear-gradient( 72.77deg, rgba(237, 238, 246, 1) 0%, rgba(237, 238, 246, 0) 100%);
    border-radius: 50px;
    width: 1048px;
    height: 576px;
    position: absolute;
    left: 199px;
    top: 1707px;
}

.ellipse-25 {
    background: #fab8c3;
    border-radius: 50%;
    width: 63px;
    height: 63px;
    position: absolute;
    left: 772px;
    top: 1780px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.ellipse-26 {
    background: var(--primary, #5956e9);
    border-radius: 50%;
    width: 31px;
    height: 31px;
    position: absolute;
    left: 1211px;
    top: 1812px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.h_3 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    width: 193px;
    height: 36px;
    position: absolute;
    left: 254px;
    top: 1815px;
    overflow: hidden;
}

.h_2 {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 254px;
    top: 1858px;
    overflow: hidden;
}

.experience-products-in-ar {
    color: var(--dark-primary, #000000);
    text-align: left;
    font: 700 60px/67px "Raleway", sans-serif;
    position: relative;
    width: 560px;
}

.h_4 {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 254px;
    top: 2019px;
    overflow: hidden;
}

.have-you-tried-augmented-reality-stop-looking-at-boring-galleries-and-start-experiences-each-item {
    color: var(--dark-secondary, #868686);
    text-align: left;
    font: 500 24px/38px "Raleway", sans-serif;
    position: relative;
    width: 483px;
    height: 170px;
}


/* .image3 {
    width: 356px;
    height: 510px;
    position: static;
} */

.ellipse-24 {
    background: #fab8c3;
    border-radius: 50%;
    width: 356px;
    height: 356px;
    position: absolute;
    left: 820px;
    top: 1820px;
}

.image-122 {
    background: linear-gradient(to left, #dcdefe, #dcdefe);
    border-radius: 33.41px;
    border-style: solid;
    border-color: transparent;
    border-width: 1.11px;
    width: 255px;
    height: 510px;
    position: absolute;
    left: 873px;
    top: 1739px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.image-132 {
    border-radius: 30px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    width: 229px;
    height: 484px;
    position: absolute;
    left: 886px;
    top: 1752px;
}

.ellipse-27 {
    background: var(--primary, #5956e9);
    border-radius: 50%;
    width: 87px;
    height: 87px;
    position: absolute;
    left: 1145px;
    top: 1594px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}

.ellipse-28 {
    background: #f7b6c1;
    border-radius: 50%;
    width: 104px;
    height: 104px;
    position: absolute;
    left: 590px;
    top: 2231px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
}


/* 
.section-1 {
    position: absolute;
    inset: 0;
} */


/* .group-760 {
    width: 548px;
    height: 328px;
    position: static;
} */

.create-an-account {
    color: var(--primary, #5956e9);
    text-align: left;
    font: 700 24px/36px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 1054px;
}

.discover-original-products {
    color: var(--dark-primary, #000000);
    text-align: left;
    font: 700 60px/67px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 1107px;
    width: 517px;
    height: 144px;
}

.there-are-more-than-950-categories-updated-daily-based-on-trending-websites-reviews-an-users-rating {
    color: var(--dark-secondary, #868686);
    text-align: left;
    font: 500 24px/38px "Raleway", sans-serif;
    position: absolute;
    left: 677px;
    top: 1268px;
    width: 548px;
}


/* .image4 {
    width: 464.61px;
    height: 700.77px;
    position: static;
} */

.image-123 {
    background: linear-gradient(to left, #dcdefe, #dcdefe);
    border-radius: 33.41px;
    border-style: solid;
    border-color: transparent;
    border-width: 1.11px;
    width: 255px;
    height: 510px;
    position: absolute;
    left: 276px;
    top: 968px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.image-133 {
    border-radius: 30px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    width: 229px;
    height: 484px;
    position: absolute;
    left: 289px;
    top: 981px;
}

.saly-225 {
    width: 73px;
    height: 218px;
    position: absolute;
    left: 488.39px;
    top: 1554.15px;
    transform-origin: 0 0;
    transform: rotate(-30deg) scale(1, -1);
}

.saly-226 {
    width: 47px;
    height: 153px;
    position: absolute;
    left: 309px;
    top: 911.83px;
    transform-origin: 0 0;
    transform: rotate(-27.837deg) scale(-1, 1);
}

.how-the-app-works {
    color: var(--dark-primary, #000000);
    text-align: center;
    font: 600 44px/36px "Raleway", sans-serif;
    opacity: 0.800000011920929;
    position: absolute;
    left: 529px;
    top: 850px;
}


/* .header {
    position: absolute;
    inset: 0;
} */

.ellipse-282 {
    background: #5956e9;
    border-radius: 50%;
    width: 1032px;
    height: 1032px;
    position: absolute;
    left: 873px;
    top: -311px;
}

.ellipse-29 {
    background: #ffdc5f;
    border-radius: 50%;
    width: 222px;
    height: 222px;
    position: absolute;
    left: -35px;
    top: -91px;
}

.ellipse-30 {
    background: #ffdc5f;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    position: absolute;
    left: 555px;
    top: 580px;
}

.ellipse-31 {
    background: #ffdc5f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 746px;
    top: 155px;
}

.sally-4 {
    width: 752px;
    height: 752px;
    position: absolute;
    left: 622px;
    top: 55px;
}

.sally-42 {
    width: 525.7px;
    height: 752px;
    position: absolute;
    left: 226.3px;
    top: 0px;
}

.saly-10 {
    width: 102.7px;
    height: 149.01px;
    position: absolute;
    left: 107.58px;
    top: 272.95px;
}

.menu {
    width: 458px;
    height: 48px;
    position: static;
}

.right {
    width: 458px;
    height: 48px;
    position: absolute;
    right: 543px;
    top: calc(50% - 2072.5px);
    overflow: hidden;
}

.desktop-menu {
    width: 560px;
    height: 48px;
    position: absolute;
    left: calc(50% - 229px);
    top: calc(50% - 24px);
    overflow: hidden;
}

.text-link {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
}

.home {
    color: var(--primary, #5956e9);
    text-align: center;
    font: 600 16px/28px "Raleway", sans-serif;
    position: relative;
}

.text-link2 {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 111px;
    top: 0px;
    overflow: hidden;
}

.team {
    color: var(--text, #252b42);
    text-align: center;
    font: 600 16px/28px "Raleway", sans-serif;
    position: relative;
}

.text-link3 {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 217px;
    top: 0px;
    overflow: hidden;
}

.faq {
    color: var(--text, #252b42);
    text-align: center;
    font: 600 16px/28px "Raleway", sans-serif;
    position: relative;
}

.text-link4 {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 319px;
    top: 0px;
    overflow: hidden;
}

.contact {
    color: var(--text, #252b42);
    text-align: center;
    font: 600 16px/28px "Raleway", sans-serif;
    position: relative;
}

.button-large2 {
    width: 204px;
    height: 70px;
    position: absolute;
    left: 178px;
    top: 510px;
}

.rectangle-22 {
    background: var(--primary, #5956e9);
    border-radius: 10px;
    width: 204px;
    height: 70px;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.button2 {
    color: var(--white, #ffffff);
    text-align: center;
    font: 700 20px "Raleway", sans-serif;
    position: absolute;
    left: 7.8px;
    top: 25px;
    width: 188.41px;
}

.get-10-off-your-first-order-when-you-spend-over-40-on-any-product {
    color: var(--secondtext, #737373);
    text-align: left;
    font: 500 24px/38px "Raleway", sans-serif;
    position: absolute;
    left: 178px;
    top: 376px;
    width: 522px;
}

.saly-8 {
    width: 583.75px;
    height: 583.75px;
    position: absolute;
    left: 737px;
    top: 372.62px;
    transform-origin: 0 0;
    transform: rotate(-63.326deg) scale(1, 1);
}

.button-large3 {
    width: 204px;
    height: 70px;
    position: absolute;
    left: 1043px;
    top: 43px;
}

.rectangle-23 {
    background: var(--pink, #fab8c3);
    border-radius: 10px;
    width: 204px;
    height: 70px;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: var( --productdropshadow-box-shadow, 0px 20px 40px 0px rgba(0, 0, 0, 0.1));
}

.button3 {
    color: var(--primary, #5956e9);
    text-align: center;
    font: 700 20px "Raleway", sans-serif;
    position: absolute;
    left: 7.8px;
    top: 25px;
    width: 188.41px;
}

.ellipse-312 {
    background: #fab8c3;
    border-radius: 50%;
    width: 58px;
    height: 58px;
    position: absolute;
    left: 47px;
    top: 431px;
}

.logo {
    width: 112px;
    height: 36px;
    position: static;
}

.sweava {
    color: var(--dark-primary, #000000);
    text-align: left;
    font: 700 24px/36px "Raleway", sans-serif;
    position: absolute;
    left: 226px;
    top: 60px;
}

.rectangle-84 {
    background: #5956e9;
    width: 11px;
    height: 11px;
    position: absolute;
    left: 203px;
    top: 69px;
}

.rectangle-85 {
    background: #ffdc5f;
    width: 11px;
    height: 11px;
    position: absolute;
    left: 208px;
    top: 75px;
}

#motion-demo {
    offset-path: path("M20,20 C10,150 200,200 200,60");
    animation: move 3000ms infinite alternate ease-in-out;
}

#motion-demo-sec {
    offset-path: path("M10,20 C100,0 20,2 2,60");
    animation: move 3000ms infinite alternate ease-in-out;
}

@keyframes move {
    0% {
        offset-distance: 0%;
    }
    100% {
        offset-distance: 100%;
    }
}