/* Imageresizer.css */

.container {
    text-align: center;
    margin-top: 50px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #c5b9b9;
}

.title {
    margin-bottom: 20px;
}

.image-upload {
    margin-bottom: 20px;
}

.upload-label {
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    display: inline-block;
    color: #007bff;
    font-weight: bold;
}

.output {
    margin-top: 20px;
}

.preview-label {
    margin-bottom: 10px;
}

.controls {
    margin-top: 20px;
}

.control-group {
    margin-bottom: 10px;
}

/* label {
    margin-right: 5px;
    font-weight: bold;
} */
/* 
input[type='number'] {
    width: 60px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
} */

.quality-group {
    margin-bottom: 20px;
}

input[type='range'] {
    width: 200px;
    margin-left: 5px;
}

.quality-value {
    margin-left: 5px;
    font-weight: bold;
}

#resizeButton {
    padding: 10px 20px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

#resizeButton:hover {
    background-color: #218838;
}

