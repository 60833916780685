.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
    z-index: 10000;
}

.text-wk-align {
    text-align: -webkit-center;
}

.apexcharts-toolbar {
    display: none !important;
}

input[type="radio"]:checked+span {
    display: block;
}

@media screen and (max-width:800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}


/* color-picker style  */

#preview {
    background: transparent;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
    display: flex;
    justify-content: center;
}

.ql-align-right {
    text-align: right;
    display: flex;
    justify-content: right;
}

#motion-demo {
    offset-path: path("M20,20 C10,150 200,200 200,60");
    animation: move 3000ms infinite alternate ease-in-out;
}

#motion-demo-sec {
    offset-path: path("M10,20 C100,0 20,2 2,60");
    animation: move 3000ms infinite alternate ease-in-out;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

@keyframes move {
    0% {
        offset-distance: 0%;
    }
    100% {
        offset-distance: 100%;
    }
}