.EditSaleProductsBar-modal {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.92);
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    overflow: hidden;
    flex-direction: column;
    margin: 16px;
    padding-bottom: 24px;
    z-index: 1203;
    border-radius: 12px;
    box-shadow: -24px 12px 32px -4px rgba(145, 158, 171, 0.16);
}