@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

.Toastify__toast-container--top-right {
    z-index: 999999 !important;
}

.year-picker {
    width: 100% !important;
}

.year-input {
    --tw-text-opacity: 1 !important;
    color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    padding: 0.625rem !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
    --tw-border-opacity: 1 !important;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
    border-width: 1px !important;
    border-radius: 0.5rem !important;
}

.zoom {
    animation: scale 1.3s linear infinite;
}

@keyframes scale {
    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;